<template>
  <div>
    <TableAggregationsToolbar v-if="aggregations" :aggregations="aggregations"> </TableAggregationsToolbar>
    <b-skeleton v-if="isLoading && !aggregations" width="300px" height="24px"></b-skeleton>
  </div>
</template>

<script>
import TableAggregationsToolbar from '@/components/common/table-aggregations-toolbar';
import * as odataService from '@/core/common/services/odata.service';

export default {
  components: { TableAggregationsToolbar },
  props: {
    filter: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      aggregations: null,
    };
  },
  watch: {
    filter: {
      immediate: true,
      handler: async function (f) {
        const aggregationData = await this.getAggregationsByFilter(f);
        this.aggregations = [
          {
            data: (aggregationData && aggregationData.count) || '-',
            title: this.$t('REISETERMINE.ANZAHLREISETERMINE'),
            iconClass: 'flaticon2-placeholder',
          },
          {
            data: (aggregationData && aggregationData.paxSum) || '-',
            title: this.$t('REISETERMINE.SUMMEALLERISTPAX'),
            iconClass: 'flaticon2-user-1',
          },
          {
            data: (aggregationData && this.$options.filters.currency(aggregationData.umsatzSum)) || '-',
            title: this.$t('REISETERMINE.SUMMEALLERUMSAETZE'),
            iconClass: 'flaticon-piggy-bank',
          },
        ];
      },
    },
  },
  methods: {
    async getAggregationsByFilter(filter) {
      this.isLoading = true;
      const transform = [
        {
          filter,
        },
        {
          aggregate: [
            {
              id: {
                with: 'countdistinct',
                as: 'count',
              },
            },
            {
              umsatz: {
                with: 'sum',
                as: 'umsatzSum',
              },
            },
            {
              ['pax/ist']: {
                with: 'sum',
                as: 'paxSum',
              },
            },
          ],
        },
      ];
      return odataService
        .getReisetermin({
          count: true,
          transform: transform,
        })
        .then(response => {
          return response.data[0];
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
