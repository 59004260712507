<template>
  <span class="d-inline align-items-center d-flex flex-wrap my-2">
    <span
      v-for="aggregation in aggregations"
      :key="aggregation.id"
      :title="aggregation.title"
      class="text-muted font-weight-bold mr-lg-5 mr-3 mb-lg-0 mb-2"
    >
      <slot v-bind:aggregation="aggregation">
        <i class="mr-1" :class="aggregation.iconClass"></i>
        {{ aggregation.data }}
      </slot>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    aggregations: {
      type: Array,
      required: true,
    },
  },
};
</script>
